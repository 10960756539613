<template>
  <div>
    <h2>{{ message }}</h2>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",

  data () {
    return {
      message: " ",
    }
  },
  mounted () {
    var hour = new Date().getHours();

    if (hour < 12) 
    {
      this.message = " Good Morning";
    }
    else if (hour < 18) 
    {
      this.message = " Good Afternoon";
    }
    else
    {
      this.message = " Good Evening";
    }
  },
};


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
