<template>
  <Header />
  <transition name="page-animation" enter-active-class="animated fadeIn">
    <router-view />
  </transition>
  <Footer />
</template>

<script>
  import Header from '@/components/Header.vue';
  import Footer from '@/components/Footer.vue';
  

  export default {
    components : {
      Header,
      Footer
    },
    
  }
</script>

<style>
  @import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.compat.css";

  #app {
    font-family: Lato, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
