<template>
    <header>
        <div class="wrapper">
            <div class="row">
                <div class="col-40" style="display:flex; justify-content:start;">
                    
                      <!--Hamburger Menu-->
                    <button class="menu burger-one refButton1" 
                        onclick="this.classList.toggle('opened');this.setAttribute('aria-expanded', this.classList.contains('opened'))" 
                        v-on:click="displayNavMenu" aria-label="Main Menu">
                        <svg width="50" height="40" viewBox="0 0 100 100">
                        <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                        <path class="line line2" d="M 20,50 H 80" />
                        <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                        </svg>
                        </button>
                    
                    <h1> Holl-<span style="color: gold;">ED</span> CENTRE </h1>
                </div>
                <div class="col-60" v-if="displayNav">
                    <nav>
                        <ul>
                            <li> <router-link to="/"> Home </router-link> </li>
                            <li> <router-link to="/services"> Services </router-link> </li>
                            <li> <router-link to="/gallery"> Gallery </router-link> </li>
                            <li> <router-link to="/contact"> Contact </router-link> </li>
                        </ul>
                    </nav>
                </div>
                <div id="mobileNavMenu" class="displayNone col-100">
                    <nav style="float: center;">
                        <ul style="text-align: center;">
                            <li> <router-link to="/"> Home </router-link> </li>
                            <li> <router-link to="/services"> Services </router-link> </li>
                            <li> <router-link to="/gallery"> Gallery </router-link> </li>
                            <li> <router-link to="/contact"> Contact </router-link> </li>
                        </ul>
                    </nav>
                </div>
            </div>          
        </div>
    </header>
</template>

<style scoped>
.displayNone {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 5px;
    background: linear-gradient(white,var(--primary-color),white);
    z-index: 1000;
    animation-name: smoothNavMenuDisplayNone;
    animation-duration: 1s;
    transition-timing-function: ease-out;
    margin-top: -44.5%;
    opacity: 0;
}

@keyframes smoothNavMenuDisplayNone{
  0%{
    margin-top: 3.5%;
    opacity: 1;
  }
  30%{
      opacity: 0;
  }
  100%{
    margin-top: -44.5%;
    opacity: 0;
  }
}
/*.display {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 5px;
    background: linear-gradient(white,var(--primary-color),white);
    z-index: 1000;
    margin-top: 3.5%;
    opacity: 1;
}*/
.display{
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 5px;
  background: linear-gradient(white,var(--primary-color),white);
  z-index: 1000;
  animation-name: smoothNavMenuDisplay;
  animation-duration: 1s;
  transition-timing-function: ease;
}

@keyframes smoothNavMenuDisplay{
  0%{
    margin-top: -44.5%;
    opacity: 0;
  }
  40%{
      opacity: 0;
  }
  90%{
      opacity: 1;
  }
  100%{
    margin-top: 3.5%;
    opacity: 1;
  }
}
.display nav ul li {
    padding-bottom: 15px;
}
header {
    position: fixed; /* Set the navbar to fixed position */
    top: 0;
    width: 100%;
    z-index: 999;
}

/*Site specific*/

header{
 background-color: var(--primary-color);
 padding-top: 10px;
 padding-bottom: 10px;
 color: #000;
}

ul li{
 list-style: none;
}
header nav{
 text-align: right;
}

    header nav a{
 color: #000;
 font-weight: bold;
 text-decoration: none;
 padding: 10px;
  margin: 0;
}

header nav a:hover {
    border-bottom: 2px #fff solid;
}
nav a.router-link-exact-active {
  color: #fff;
  font-weight: bolder;
}

@media screen and (min-width: 768px){
    header nav{
        text-align: right;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    header nav li{
        display: inline-block;
    }
}

/*Begin burger styling*/
.burger-one{
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  padding: 0;
}

.line {
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
/*End burger styling*/
</style>

<script>
    export default {
        data () {
            return {
                displayNav: true,
            }
        },

        mounted () {
            /*if ((screen.width || window.innerWidth) < 768)
            {
                this.displayNav = false;
            }*/
            if ((screen.width) < 768)
            {
                this.displayNav = false;
            }
            if (screen.width >= 768)
            {
                document.querySelector('.burger-one').style.display = "none";
            }
        },
        methods: {
            displayNavMenu: function () {
                let mobileMenu = document.getElementById('mobileNavMenu');
                //let mobileMenu = document.querySelector('#mobileMenuNav');

                mobileMenu.classList.toggle('displayNone');
                mobileMenu.classList.toggle('display');

            } 
        }
    }
</script>