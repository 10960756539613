<template>
    	<!--Begin the footer section -->
		<footer>
			<div class="row" style="margin-bottom: 30px;">
				<div class="wrapper">
					
					<div class="col-33" style="margin-bottom: 30px;">
						<div class="card-body">
							<div class="times">
								<ul style="float: right">
								<li><p>Accounting</p></li>
								<li><p>Business Studies</p></li>
								<li><p>Computer Applications Technology</p></li>
								<li><p>Engineering Graphics and Design</p></li>
								<li><p>Life Sciences</p></li>
								<li><p>Mathematics</p></li>
								<li><p>Physical Science</p></li>
								<li><p>Tourism</p></li>
								<li><p>Visual Arts</p></li>
								</ul>  
							</div>
						</div>
					</div>
				

				<div class="col-33" style="margin-bottom: 30px;">
					<div class="card-body">
			          <div class="times">
			            <ul>
			              <li><p>Times</p></li>
						
			              <li style="display:flex;justify-content:space-between;">
							  <p>Monday : </p>
							  <p>07:30 - 14:30</p>
						  </li>
						  <li style="display:flex;justify-content:space-between;">
							  <p>Tuesday : </p>
							  <p>07:30 - 14:30</p>
						  </li>
						  <li style="display:flex;justify-content:space-between;">
							  <p>Wednesday : </p>
							  <p>07:30 - 14:30</p>
						  </li>
						  <li style="display:flex;justify-content:space-between;">
							  <p>Thursday : </p>
							  <p>07:30 - 14:30</p>
						  </li>
						  <li style="display:flex;justify-content:space-between;">
							  <p>Friday : </p>
							  <p>07:30 - 12:00</p>
						  </li>
						  <li style="display:flex;justify-content:space-between;">
							  <p>Saturday : </p>
							  <p>Closed</p>
						  </li>
						  <li style="display:flex;justify-content:space-between;">
							  <p>Sunday : </p>
							  <p>Closed</p>
						  </li>
			            </ul>  
			          </div>
			        </div>
				</div>
				<div class="col-33">
					<div class="card-body">
			          <div class="times">
			            <ul>
			              <li><p>Contact details <span></span></p></li>
						  <li style="display:flex;justify-content:space-between;">
							  <p>Email : </p>
							  <p>info.holledcentre@gmail.com</p>
						  </li>
						  <li style="display:flex;justify-content:space-between;">
							  <p>Tel : </p>
							  <p>011 432 2997</p>
						  </li>
						  <li style="display:flex;justify-content:space-between;">
							  <p>Cell : </p>
							  <p>083 324 8854</p>
						  </li>
			            </ul>  
			          </div>
			        </div>
				</div>
				
				
			</div>
			</div>
			
				<div class="wrapper" style="width:100%; padding-left: 0; padding-right: 0; margin: 0;">
		            <div class="bottom">
		                <div class="footerImg" style="margin-Bottom: 30px;">
                            <a href="#"><img style="width: 150px; height: 150px;" src="../assets/img/logo.png" alt="logo"></a>
		                </div>
		                <div style="background: var(--primary-color); padding-bottom: 20px;">
		                    <p style="text-align: center;">
		                    	<a ><span style="color: var(--primary-color);">Holistic Education Centre</span></a><br>
		                        Copyright ©2021 All rights reserved <!--|-->
		                        
		                    </p>
		                </div>
		            </div>
		        </div>
		    
		</footer>
        <!-- End the footer section -->
</template>

<style scoped>

    .times {
  /*background: lightblue;*/
  /*background-color: var(--primary-color);*/
  /*background-color: lightgreen;*/
  width: 100%;
}

.times .top {
  width: 100%;
  padding: 5px 20px 5px 20px;
  min-height: 21px;
  box-sizing: border-box;  
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.times .bottom {
  background: #fafafa;
  width: 100%;
  padding: 5px 10px 1px 10px;
  min-height: 50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;  
}

.times .bottom h2, .times .bottom p {
  color: #555;
  font-size: 0.8em;
}

.times h2 {
  color: white;
  margin: 0 0 20px 0;
}

.times ul {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.times ul li {
  /*background: rgb(101, 184, 181);*/
  background: var(--primary-color);
  color: #000;
  width: 100%;
  padding: 5px 20px 1px 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;   
  border-top: 1px solid #197854;
}

.times ul li.active,.times ul li:hover {
  background: rgb(231, 223, 72);
  box-shadow: 1px 3px 14px #24805B;
  display:inline-block;
}

.times ul li p {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}

.times i {
  margin-right: 1em;
}

.times p {
  color: black;
  padding: 0;
}

.times span {
  float: right;
}
</style>