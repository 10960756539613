<template>
  <div class="contact" style="margin-top:80px;">
    <h1>GET IN TOUCH</h1>
  </div>
  
  <div class="row" style="margin-top: 30px;margin-bottom: 30px;">
        <div class="container">
        <form>
          <div class="row">
            <div class="col-50" style="padding:0 10px 0 0;">
              <label>Name</label>
              <input 
                type="text" 
                v-model="name"
                name="name"
                placeholder="Your Name"
              >
            </div>
            <div class="col-50" style="padding:0 10px 0 0;">
              <label>Surname</label>
              <input 
                type="text" 
                v-model="surname"
                name="surname"
                placeholder="Your Surname"
              >
            </div>
          </div>
          <div class="row">
            <div class="col-50" style="padding:0 10px 0 0;">
            <label>Email</label>
          <input 
            type="email" 
            v-model="email"
            name="email"
            placeholder="Your Email"
            >
          </div>
            <div class="col-50" style="padding:0 10px 0 0;">
              <label>Phone number</label>
              <input 
                type="text" 
                v-model="surname"
                name="surname"
                placeholder="Phone number"
              >
            </div>
          </div>
          
          <div class="col-100" style="padding:0 10px 0 0;">
            <label>Subject</label>
          <input 
            type="email" 
            v-model="email"
            name="email"
            placeholder="Subject"
            >
          </div>
          <div class="col-100" style="padding:0 10px 0 0;">
            <label>Message</label>
          <textarea 
            name="message"
            v-model="message"
            cols="30" rows="5"
            placeholder="Message">
          </textarea>
          </div>
          
          <input type="submit" value="Send">
        </form>
    </div>
  </div>

  <div class="row" style="margin-bottom:30px;">
    <div class="col-100">
      <div>
        Please note that the email functionality on this website is currently not functionining.
        <br />
        Contact Holistic Education Centre by sending an email directly to 
        <li>info.holledcentre@gmail.com</li>
        <br />
        Alternatively you can dial:
          <ul>
            <li>Tel:0</li>
            <li>Cell: 0</li>
          </ul>
      </div>
    </div>
  </div>
</template>

<!--<template>
    
</template>-->


<style scoped>

.container {
  display: block;
  margin:auto;
  text-align: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 90%;
}
@media screen and (min-width: 768px) {
  .container {
    width: 50%;
  }
}

label {
  float: left;
}

input[type=text], [type=email], textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: var(--primary-color);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: lightgrey;
}
</style>