<template>
  <!--White Knight Rider Animation-->
  <div class="row m-t-60">
    <div class="col-100">
      <div class="line-break-w">
        
      </div>
    </div>
  </div>

  <div class="showcase m-b-50" style="padding-top: 50px;">
    <div class="wrapper">
      <div class="row">
        <div class="col-60">
            <div class="contactDetails">
                <h1>Holistic Education Centre</h1>
                <p>
                  says
                  <br /><br />
                  <HelloWorld />
                </p>
            </div>
                  
        </div>
        <div class="col-40">
          <div class="flex showcase-logo">
              <img src="../assets/img/logo.png" alt="Holl-ED Logo" class="showcaseLogoSize">
          </div>
        </div>
      </div>
    </div>
  </div>
  

  <div class="row" style="position:relative; margin-top: 30px; margin-bottom: 30px;">
    <div class="col-100">
      <div class="contactDetails">
        <h3 class="text-center my-1">
            Home Schooling Centre located in the South of Johannesburg<br>
            63A Mount Pellan Dr<br />
            Glenvista
            <br /><br />
            Tel: 011 432 2997<br>
            Cell: 083 324 8854<br>
            Email: info.holledcentre@gmail.com        
        </h3>
      </div>
    </div>
  </div>
    <div class="row" style="margin-bottom: 30px;">
      <div class="col-100">
        <div class="line-break">
          
        </div>
      </div>
    </div>
  <div class="wrapper">

    <div class="row" style="margin-bottom: 30px;">
      <div class="col-100">
        <div class="card">
                <h3 id="h3" style="text-align: center;">Ethos</h3>
                <br />
                <p style="color: #555; font-weight: bolder;">
                    Child development does not mean developing your child into the person society thinks they should be, 
                    but helping them holistically into the best person they were destined to be.
                <br><br>
                "Every student can learn just not on the same day or the same way" - we provide education in a safe, 
                    positive environment where each student will receive individualised attention with our small classes of 12 students.
                <br><br>
                We believe in strong relationships with our parents, 
                committing to work together to build a strong foundation that nutures each student's self-esteem and confidence.
              </p>
            </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 30px;">
      <div class="col-33" style="margin-bottom: 30px;">
          <div class="">
            <img src="../assets/img/believe1.jpg" alt="believer" style="width:100%;">
          </div>
      </div>
      <div class="col-67">
        <div class="card" style="background: linear-gradient(white,var(--primary-color))">
          <h3 id="h3" style="text-align: center;">Pre-Primary Phase</h3>
          <br />
          <p style="color: #555; font-weight: bolder;">
              A home away from home. Loving environment with a 6 to 1 ratio.
              <br><br>
              We provide breakfast, a mid-morning snack, a healthy cooked lunch and a mid-afternoon snack. 
              Our education programme include: Singing Sensations, Actionball (gross and fine motor development), 
              Baking, Pottery and theme based art.
              <br><br>
              Ages: 3 months to Grade 00
          </p>
        </div>
      </div>
    </div>

    <div class="row flex" style="margin-bottom: 30px;">
      <div class="col-67">
        <div class="card" style="background: linear-gradient(white,var(--primary-color))">
                <h3 id="h3" style="text-align: center;">Primary Phase</h3>
                <br />
                <p style="color: #555; font-weight: bolder;background: linear-gradient(white,var(--primary-color)">
                    A nurturing environment where children are encouraged to learn and play.
                    <br><br>
                    Integrated classes: classes contain multiple grades because it has great 
                    influence on the cultural development of the child. This is obtained by the 
                    relations of the children among themselves. You cannot imagine how well a 
                    young child learns from an older child; how patient the older child is 
                    with the difficulties of the younger.
                    <br><br>
                    Grade 0 to Grade 6.
                </p>
            </div>
      </div> 
    </div>
    <div class="row" style="margin-bottom: 30px;">
      <div class="col-100">
        <div id="secondary-phase" class="card">
          <div class="content">
            <h3 id="h3" style="text-align: center;">
              Secondary Phase
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-bottom: 30px;">
      <div class="col-100">
        <div class="card">
          <div class="content">
                <h5>Senior Phase</h5>
                <p style="color: #555; font-weight: bolder;" class="threeD">
                    The final phase of compulsory education.
                </p>
                <p style="color: #555; font-weight: bolder;">
                Structured environment where all subjects are tutored daily with 
                special attention given to 
                <br>English (Home language) 
                Afrikaans (First additional language) 
                <br>and Mathematics.
                </p>
                <p style="color: #555; font-weight: bolder;" class="threeD">Grade 7 to Grade 9</p>
                </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-bottom: 30px;">
      <div class="col-100">
        <div class="card">
          <div class="content">
                <h5>FET Phase</h5>
                <p style="color: #555; font-weight: bolder;">Further Education Training where students select their 3 chosen subjects 
                    <br>and English, Afrikaans, Life Orientation and Mathematics/Math Literacy.<br>
                    Subjects: 
                    <br>Accounting, Business studies,
                    Engineering Graphics and Design,
                    <br>Computer Application Technology, 
                    Geography, History, Hospitality, Tourism,
                    <br>Physical Science, Life Science and Visual Arts.</p>
                    
                    <p style="color: #555; font-weight: bolder;">Matriculation: SACAI National Senior Certificate.</p>
                    
                    <p style="color: #555; font-weight: bolder;" class="threeD">Grade 10 to Grade 12.</p>
                </div>
        </div>
      </div>
    </div>

    <div class="my-4 text-center"> 
      <div class="col-60 balance-150" style="margin-bottom: 30px;">
        <div class="inner-row">
            <div class="col-50" style="margin-bottom: 30px;">
              <h3>EDUCATION</h3>
              <p class="text-secondary">Future</p>
            </div>
            <div class="col-50">
              <h3>12 Students MAX </h3>
              <p class="text-secondary">per Class</p>
            </div>       
        </div>
      </div>
      <div class="col-40">
        <img src="../assets/img/do1.png" alt="5 Terre" style="width:100%;">
      </div>
    </div>

    <div class="row">
      <div class="col-60">
        <div class="wrap">
            <div class="box-area">
                <div class="side-1">
                    <img src="../assets/img/logo.png">
                </div>
                <div class="side-2">
                    <img src="../assets/img/logo.png">
                </div>
                <div class="side-3">
                    <img src="../assets/img/logo.png">
                </div>
                <div class="side-4">
                    <img src="../assets/img/logo.png">
                </div>
                <div class="side-5">
                    <img src="../assets/img/logo.png">
                </div>
                <div class="side-6">
                    <img src="../assets/img/logo.png">
                </div>
            </div>
          </div>
      </div>
      <div class="col-40" style="margin-bottom: 30px;">
        <div class="row balance-70">
          <div class="card" style="margin-bottom: 40px;">
            <h3>Impaq Registered Centre</h3>
          </div>
        </div>
        <div class="row" style="margin-bottom:40px;">
          <div class="card">
            <h3>Tutoring Centre</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld
  },
  mounted () {

  }
};

</script>

<style scoped>
  /*Margins and Padding*/
  .m-t-60 {
    margin-top: 60px;
  }
  .m-b-50 {
    margin-bottom: 50px;
  }
  /*Begin Showcase */
  .showcase {
    height: 360px;
    background-color: var(--primary-color);
    color: #fff;
    position: relative;
  }
  .showcase h1 {
    font-size: 25px;
  }
  .showcase p {
    margin: 20px 0;
    font-weight: bold;
    color: #555;
  }
  .showcase-logo {
    position: relative;
    width: 150px;
    background-color:white; 
    border-radius: 50%;
    z-index: 100;
  }
  .showcaseLogoSize {
      width:150px; 
      height: 150px;
  }
  .showcase::before,
  .showcase::after {
    content: '';
    position: absolute;
    height: 100px;
    bottom: -70px;
    right: 0;
    left: 0;
    background: #fff;
    transform: skewY(-3deg);
    -webkit-transform: skewY(-3deg);
    -moz-transform: skewY(-3deg);
    -ms-transform: skewY(-3deg);
  }
  /*End Showcase*/
  .contactDetails {
    text-align: center;
  }
  .contactDetails h1 {
      color: #fff;
      text-transform: uppercase;
      background: linear-gradient(to right, #befc03 10%, #fff 50%, #fff 60%);
      background-size: auto auto;
      /*background-clip: border-box;*/
      background-size: 200% auto;
      color: #fff;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: textclip 1.5s linear infinite;
      display: inline-block;
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }

.contactDetails h3 {
  background: linear-gradient(to right, #befc03 10%, #333 50%, #00EAFF 60%);
  background-size: auto auto;
  /*background-clip: border-box;*/
  background-size: 200% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1.5s linear infinite;
  display: inline-block;
}
#h3 {
  font-size: 42px;
  background: -webkit-linear-gradient(#00EAFF, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}

h3 {
  background: -webkit-linear-gradient(#00EAFF, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.my-4 {
  margin: 1rem 0;
}
.text-center {
  text-align: center;
}

.card {
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px 0;
}


/*Begin Rotating Cube*/
.wrap{
	width: 200px;
	height: 200px;
	margin: 60px auto;
}
.box-area{
	width: 200px;
	animation: animate 15s linear infinite;
	transform-style: preserve-3d;
}
.box-area img{
	width: 200px;
	height: 200px;
}
.box-area div{
	position: absolute;
	width: 200px;
	height: 200px;
}
.side-1{
	transform: translateZ(100px);
}
.side-2{
	transform: rotateY(180deg) translateZ(100px);
}
.side-3{
	transform: rotateY(-90deg) translateX(-100px);
	transform-origin: left;
}
.side-4{
	transform: rotateY(90deg) translateX(100px);
	transform-origin: right;
}
.side-5{
	transform: rotateX(-90deg) translateY(-100px);
	transform-origin: top;
}
.side-6{
	transform: rotateX(90deg) translateY(100px);
	transform-origin: bottom;
}
@keyframes animate{
	0%{
		transform: rotateY(0deg);
	}
	100%{
		transform: rotateY(360deg);
	}
}
/*End Rotating Cube*/

</style>
